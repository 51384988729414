<template>
  <div class="productOverall">
    <!-- <div class="main-Title bgff"><h2>Sub Rule Add</h2></div> -->
    <div class="mainBox">
      <el-row type="flex">
        <el-col :span="24">
          <el-form ref="postData" :model="postData" :rules="rules" size="mini">
            <el-card shadow="hover"><h2 class="ml0">Sub id create rule</h2></el-card>
            <el-card shadow="hover" class="mb10">
              <el-row :gutter="30">
                <el-col :span="24">
                  <el-form-item label="rule Name" prop="ruleName" label-width="90px">
                    <el-input v-model="postData.ruleName" placeholder="Please enter rule Name" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="min count" prop="minCount" label-width="90px">
                    <el-input
                      v-model.number="postData.minCount"
                      @keyup.native="UpNumber"
                      @keydown.native="UpNumber"
                      placeholder="Please enter min count"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="max count" prop="maxCount" label-width="90px">
                    <el-input
                      v-model.number="postData.maxCount"
                      @keyup.native="UpNumber"
                      @keydown.native="UpNumber"
                      placeholder="Please enter max count"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="sub daily of cap" prop="dailyCap" label-width="120px">
                    <el-input
                      v-model.number="postData.dailyCap"
                      @keyup.native="UpNumber"
                      @keydown.native="UpNumber"
                      placeholder="Please enter sub daily of cap"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="sub total cap" prop="totalCap" label-width="120px">
                    <el-input
                      v-model.number="postData.totalCap"
                      @keyup.native="UpNumber"
                      @keydown.native="UpNumber"
                      placeholder="Please enter sub daily of cap"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30">
                <el-col :span="1" align="right" style="padding-right:0;padding-top: 5px;">
                  <el-checkbox
                    v-model="postData.reuse"
                    :true-label="1"
                    :false-label="0"
                    style="margin-right: 0;"
                  ></el-checkbox>
                </el-col>
                <el-col :span="7">
                  <el-form-item
                    label="reuse cooldown hours"
                    prop="cooldownHours"
                    label-width="150px"
                  >
                    <el-input
                      v-model.number="postData.cooldownHours"
                      :disabled="!postData.reuse"
                      placeholder="Please enter reuse cooldown hours"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="max active hours" prop="activeHours" label-width="130px">
                    <el-input
                      v-model="postData.activeHours"
                      placeholder="Please enter max active hours"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="generator" prop="generator" label-width="80px">
                    <el-select
                      v-model="postData.generator"
                      placeholder="Please Select generator"
                      size="mini"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in options.generator"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
            <el-card shadow="hover"
              ><h2 class="ml0">Sub change rules(rejection postback)</h2></el-card
            >
            <el-card shadow="hover" class="mb10">
              <el-row>
                <el-col :span="24"
                  ><el-button type="primary" size="mini" @click="rejectionPostbackAdd"
                    >add</el-button
                  ></el-col
                >
                <el-col :span="24"><el-divider></el-divider></el-col>
              </el-row>
              <el-row
                :gutter="30"
                class="mb10"
                v-for="(rejectionPostback, index) in postData.rejectionPostbackList"
                :key="index"
              >
                <el-col :span="12">
                  <el-form-item
                    label="rejection reason"
                    :prop="`rejectionPostbackList[${index}].rejectionReason`"
                    label-width="130px"
                    :rules="{
                      required: true,
                      message: 'Please select rejection reason',
                      trigger: 'blur',
                    }"
                  >
                    <el-select
                      v-model="rejectionPostback.rejectionReason"
                      placeholder="Please Select rejection reason"
                      size="mini"
                      filterable
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in options.rejectionReason"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="continuous rejection count"
                    :prop="`rejectionPostbackList[${index}].rejectionCount`"
                    label-width="190px"
                    :rules="{
                      required: true,
                      message: 'Please enter continuous rejection count',
                      trigger: 'blur',
                    }"
                    @keyup.native="UpNumber"
                    @keydown.native="UpNumber"
                  >
                    <el-input
                      v-model="rejectionPostback.rejectionCount"
                      placeholder="Please enter continuous rejection count"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="" prop="">
                    <el-row>
                      <!--                      <el-col :span="7">-->
                      <!--                        <el-checkbox-->
                      <!--                          label="manager alert"-->
                      <!--                          :true-label="1"-->
                      <!--                          :false-label="0"-->
                      <!--                          v-model="rejectionPostback.managerAlert"-->
                      <!--                        ></el-checkbox>-->
                      <!--                      </el-col>-->
                      <!--                      <el-col :span="7">-->
                      <!--                        <el-checkbox-->
                      <!--                          label="enginer alert"-->
                      <!--                          :true-label="1"-->
                      <!--                          :false-label="0"-->
                      <!--                          v-model="rejectionPostback.enginerAlert"-->
                      <!--                        ></el-checkbox>-->
                      <!--                      </el-col>-->
                      <el-col :span="7">
                        <el-checkbox
                          label="change sub"
                          :true-label="1"
                          :false-label="0"
                          v-model="rejectionPostback.changeSub"
                        ></el-checkbox>
                      </el-col>
                      <el-col :span="7">
                        <el-checkbox
                          label="exclude vba"
                          :true-label="1"
                          :false-label="0"
                          v-model="rejectionPostback.excludeVba"
                        ></el-checkbox>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="continuous change count"
                    :prop="`rejectionPostbackList[${index}].changeCount`"
                    label-width="180px"
                    :rules="[
                      {
                        required: true,
                        message: 'Please enter continuous change count',
                        trigger: 'blur',
                      },
                      {
                        type: 'number',
                        min: 1,
                        max: 99999,
                        message: 'Please enter a number greater than 0',
                        trigger: 'blur',
                      },
                    ]"
                    @keyup.native="UpNumber"
                    @keydown.native="UpNumber"
                  >
                    <el-input
                      v-model.number="rejectionPostback.changeCount"
                      placeholder="Please enter continuous change count"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="change interval"
                    :prop="`rejectionPostbackList[${index}].changeIntervalStr`"
                    label-width="120px"
                    :rules="{
                      required: true,
                      message: 'Please select change interval',
                      trigger: 'blur',
                    }"
                  >
                    <el-select
                      v-model="rejectionPostback.changeIntervalStr"
                      placeholder="Please Select change interval"
                      size="mini"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in options.changeInterval"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="24" align="right"
                  ><el-button type="danger" size="mini" @click="rejectionPostbackDelete(index)"
                    >delete</el-button
                  ></el-col
                >
                <el-col :span="24"><el-divider></el-divider></el-col>
              </el-row>
            </el-card>
            <!--            <el-card shadow="hover"><h2 class="ml0">Sub change rules(report anaylsis)</h2></el-card>-->
            <el-card shadow="hover">
              <!--              <el-row>-->
              <!--                <el-col :span="24"-->
              <!--                  ><el-button type="primary" size="mini" @click="reportAnaylsisAdd"-->
              <!--                    >add</el-button-->
              <!--                  ></el-col-->
              <!--                >-->
              <!--              </el-row>-->
              <!--              <el-row-->
              <!--                :gutter="30"-->
              <!--                v-for="(reportAnaylsis, index) in postData.reportAnalysisList"-->
              <!--                :key="index"-->
              <!--              >-->
              <!--                <el-col :span="24"><el-divider></el-divider></el-col>-->
              <!--                <el-col :span="8">-->
              <!--                  <el-form-item-->
              <!--                    label="check interval"-->
              <!--                    :prop="`reportAnalysisList[${index}].checkIntervalStr`"-->
              <!--                    :rules="{-->
              <!--                      required: true,-->
              <!--                      message: 'Please Select check interval',-->
              <!--                      trigger: 'blur',-->
              <!--                    }"-->
              <!--                    label-width="120px"-->
              <!--                  >-->
              <!--                    <el-select-->
              <!--                      v-model="reportAnaylsis.checkIntervalStr"-->
              <!--                      placeholder="Please Select check interval"-->
              <!--                      size="mini"-->
              <!--                      style="width: 100%;"-->
              <!--                    >-->
              <!--                      <el-option-->
              <!--                        v-for="item in options.checkInterval"-->
              <!--                        :key="item"-->
              <!--                        :label="item"-->
              <!--                        :value="item"-->
              <!--                      ></el-option>-->
              <!--                    </el-select>-->
              <!--                  </el-form-item>-->
              <!--                </el-col>-->
              <!--                <el-col :span="8">-->
              <!--                  <el-form-item-->
              <!--                    label="Aff"-->
              <!--                    :prop="`reportAnalysisList[${index}].affiliateId`"-->
              <!--                    label-width="30px"-->
              <!--                    :rules="{-->
              <!--                      required: true,-->
              <!--                      message: 'Please Select Aff',-->
              <!--                      trigger: 'blur',-->
              <!--                    }"-->
              <!--                  >-->
              <!--                    <el-select-->
              <!--                      v-model="reportAnaylsis.affiliateId"-->
              <!--                      placeholder="Please Select Aff"-->
              <!--                      size="mini"-->
              <!--                      style="width: 100%;"-->
              <!--                    >-->
              <!--                      <el-option-->
              <!--                        v-for="item in options.aff"-->
              <!--                        :key="item.id"-->
              <!--                        :label="item.company"-->
              <!--                        :value="item.id"-->
              <!--                      ></el-option>-->
              <!--                    </el-select>-->
              <!--                  </el-form-item>-->
              <!--                </el-col>-->
              <!--                <el-col :span="8">-->
              <!--                  <el-form-item-->
              <!--                    label="report period"-->
              <!--                    :prop="`reportAnalysisList[${index}].reportPeriodStr`"-->
              <!--                    label-width="110px"-->
              <!--                    :rules="{-->
              <!--                      required: true,-->
              <!--                      message: 'Please Select report period',-->
              <!--                      trigger: 'blur',-->
              <!--                    }"-->
              <!--                  >-->
              <!--                    <el-select-->
              <!--                      v-model="reportAnaylsis.reportPeriodStr"-->
              <!--                      placeholder="Please Select report period"-->
              <!--                      size="mini"-->
              <!--                      style="width: 100%;"-->
              <!--                    >-->
              <!--                      <el-option-->
              <!--                        v-for="item in options.reportPeriod"-->
              <!--                        :key="item"-->
              <!--                        :label="item"-->
              <!--                        :value="item"-->
              <!--                      ></el-option>-->
              <!--                    </el-select>-->
              <!--                  </el-form-item>-->
              <!--                </el-col>-->
              <!--                <el-col :span="24">-->
              <!--                  <el-row :gutter="20">-->
              <!--                    <el-col :span="2" class="pt10 pb10" align="right">-->
              <!--                      <el-form-item label="" prop="" class="mb0"-->
              <!--                        ><el-button type="primary" size="mini" @click="whereAdd(index)"-->
              <!--                          >add</el-button-->
              <!--                        ></el-form-item-->
              <!--                      >-->
              <!--                    </el-col>-->
              <!--                    <el-col :span="20">-->
              <!--                      <el-row-->
              <!--                        :gutter="10"-->
              <!--                        class="border-dashed pt10 pb10 mb5"-->
              <!--                        v-for="(filterBeans, filterBeansIndex) in reportAnaylsis.filterBeans"-->
              <!--                        :key="filterBeansIndex"-->
              <!--                      >-->
              <!--                        <el-col :span="6">-->
              <!--                          <el-form-item label="" prop="att" class="mb0">-->
              <!--                            <el-select-->
              <!--                              v-model="filterBeans.att"-->
              <!--                              placeholder="Please Select att"-->
              <!--                              size="mini"-->
              <!--                              style="width: 100%;"-->
              <!--                            >-->
              <!--                              <el-option-->
              <!--                                v-for="item in options.att"-->
              <!--                                :key="item"-->
              <!--                                :label="item"-->
              <!--                                :value="item"-->
              <!--                              ></el-option>-->
              <!--                            </el-select>-->
              <!--                          </el-form-item>-->
              <!--                        </el-col>-->
              <!--                        <el-col :span="6">-->
              <!--                          <el-form-item label="" prop="" class="mb0">-->
              <!--                            <el-select-->
              <!--                              v-model="filterBeans.compare"-->
              <!--                              placeholder="Please Select compare"-->
              <!--                              size="mini"-->
              <!--                              style="width: 100%;"-->
              <!--                            >-->
              <!--                              <el-option-->
              <!--                                v-for="item in options.compare"-->
              <!--                                :key="item"-->
              <!--                                :label="item"-->
              <!--                                :value="item"-->
              <!--                              ></el-option>-->
              <!--                            </el-select>-->
              <!--                          </el-form-item>-->
              <!--                        </el-col>-->
              <!--                        <el-col :span="6">-->
              <!--                          <el-form-item label="" prop="" class="mb0">-->
              <!--                            <el-input-->
              <!--                              v-model="filterBeans.val"-->
              <!--                              size="mini"-->
              <!--                              style="width: 100%;"-->
              <!--                              placeholder="Please Select val"-->
              <!--                            />-->
              <!--                          </el-form-item>-->
              <!--                        </el-col>-->
              <!--                        <el-col :span="6">-->
              <!--                          <el-form-item label="" prop="" class="mb0">-->
              <!--                            <el-button-->
              <!--                              type="danger"-->
              <!--                              size="mini"-->
              <!--                              @click="whereDelete(index, filterBeansIndex)"-->
              <!--                              >delete</el-button-->
              <!--                            >-->
              <!--                          </el-form-item>-->
              <!--                        </el-col>-->
              <!--                      </el-row>-->
              <!--                    </el-col>-->
              <!--                  </el-row>-->
              <!--                </el-col>-->
              <!--                <el-col :span="24" class="pt10 pb10">-->
              <!--                  <el-form-item label="" prop="emuName">-->
              <!--                    <el-row>-->
              <!--                      <el-col :span="7">-->
              <!--                        <el-checkbox-->
              <!--                          label="manager alert"-->
              <!--                          :true-label="1"-->
              <!--                          :false-label="0"-->
              <!--                          v-model="reportAnaylsis.managerAlert"-->
              <!--                        ></el-checkbox>-->
              <!--                      </el-col>-->
              <!--                      <el-col :span="7">-->
              <!--                        <el-checkbox-->
              <!--                          label="enginer alert"-->
              <!--                          :true-label="1"-->
              <!--                          :false-label="0"-->
              <!--                          v-model="reportAnaylsis.enginerAlert"-->
              <!--                        ></el-checkbox>-->
              <!--                      </el-col>-->
              <!--                      <el-col :span="7">-->
              <!--                        <el-checkbox-->
              <!--                          label="change sub"-->
              <!--                          :true-label="1"-->
              <!--                          :false-label="0"-->
              <!--                          v-model="reportAnaylsis.changeSub"-->
              <!--                        ></el-checkbox>-->
              <!--                      </el-col>-->
              <!--                    </el-row>-->
              <!--                  </el-form-item>-->
              <!--                </el-col>-->
              <!--                <el-col :span="12">-->
              <!--                  <el-form-item-->
              <!--                    label="continuous change count"-->
              <!--                    :prop="`reportAnalysisList[${index}].changeCount`"-->
              <!--                    label-width="180px"-->
              <!--                    :rules="[-->
              <!--                      {-->
              <!--                        required: true,-->
              <!--                        message: 'Please enter continuous change count',-->
              <!--                        trigger: 'blur',-->
              <!--                      },-->
              <!--                      {-->
              <!--                        type: 'number',-->
              <!--                        min: 1,-->
              <!--                        max: 99999,-->
              <!--                        message: 'Please enter a number greater than 0',-->
              <!--                        trigger: 'blur',-->
              <!--                      },-->
              <!--                    ]"-->
              <!--                    @keyup.native="UpNumber"-->
              <!--                    @keydown.native="UpNumber"-->
              <!--                  >-->
              <!--                    <el-input-->
              <!--                      v-model.number="reportAnaylsis.changeCount"-->
              <!--                      placeholder="Please enter continuous change count"-->
              <!--                      size="mini"-->
              <!--                    />-->
              <!--                  </el-form-item>-->
              <!--                </el-col>-->
              <!--                <el-col :span="12">-->
              <!--                  <el-form-item-->
              <!--                    label="change interval"-->
              <!--                    :prop="`reportAnalysisList[${index}].changeIntervalStr`"-->
              <!--                    label-width="120px"-->
              <!--                    :rules="{-->
              <!--                      required: true,-->
              <!--                      message: 'Please Select change interval',-->
              <!--                      trigger: 'blur',-->
              <!--                    }"-->
              <!--                  >-->
              <!--                    <el-select-->
              <!--                      v-model="reportAnaylsis.changeIntervalStr"-->
              <!--                      placeholder="Please Select change interval"-->
              <!--                      size="mini"-->
              <!--                      style="width: 100%;"-->
              <!--                    >-->
              <!--                      <el-option-->
              <!--                        v-for="item in options.changeInterval"-->
              <!--                        :key="item"-->
              <!--                        :label="item"-->
              <!--                        :value="item"-->
              <!--                      ></el-option>-->
              <!--                    </el-select>-->
              <!--                  </el-form-item>-->
              <!--                </el-col>-->
              <!--                <el-col :span="24" align="right"-->
              <!--                  ><el-button type="danger" size="mini" @click="reportAnaylsisDelete(index)"-->
              <!--                    >delete</el-button-->
              <!--                  ></el-col-->
              <!--                >-->
              <!--              </el-row>-->
              <el-row>
                <el-col :span="24"><el-divider></el-divider></el-col>
                <el-col :span="24"
                  ><el-button
                    type="primary"
                    style="width: 100%;"
                    :loading="loading.save"
                    @click="save('postData')"
                    >save</el-button
                  ></el-col
                >
              </el-row>
            </el-card>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import { getSubruleOption, addSubrule } from 'api/product/subrule';
  import { getAffiliateList } from 'api/affiliate/index';
  export default {
    components: {},
    data() {
      let validMaxCount = (rule, value, callback) => {
        if (value < this.postData.minCount) {
          callback(new Error('Max count cannot be less than min count'));
        } else {
          //这里验证成功之后一定要给一个callback，不然提交的时候验证都通过，但是valid为false
          callback();
        }
      };
      return {
        postData: {
          ruleName: '',
          status: 1,
          minCount: 0,
          maxCount: 0,
          dailyCap: 0,
          totalCap: 0,
          reuse: 0,
          cooldownHours: 0,
          activeHours: 0,
          generator: '',
          rejectionPostbackList: [],
          reportAnalysisList: [],
        },
        rules: {
          ruleName: [
            {
              required: true,
              message: 'Please enter rule Name',
              trigger: 'blur',
            },
          ],
          minCount: [
            {
              required: true,
              message: 'Please enter min count',
              trigger: 'blur',
            },
            {
              type: 'number',
              min: 1,
              max: 99999,
              message: 'Please enter a number greater than 0',
              trigger: 'blur',
            },
          ],
          maxCount: [
            {
              required: true,
              message: 'Please enter max count',
              trigger: 'blur',
            },
            { validator: validMaxCount, trigger: 'blur' },
            {
              type: 'number',
              min: 1,
              max: 100,
              message: 'Please enter a number Greater than 0 but less than 100',
              trigger: 'blur',
            },
          ],
          dailyCap: [
            {
              required: true,
              message: 'Please enter daily Cap',
              trigger: 'blur',
            },
          ],
          totalCap: [
            {
              type: 'number',
              min: 0,
              message: 'Please enter a number',
              trigger: 'blur',
            },
          ],
          activeHours: [
            {
              required: true,
              message: 'Please enter max active Hours',
              trigger: 'blur',
            },
          ],
          generator: [
            {
              required: true,
              message: 'Please select generator',
              trigger: 'blur',
            },
          ],
        },
        loading: {
          save: false,
        },
        options: {
          att: [],
          compare: [],
          changeInterval: [],
          checkInterval: [],
          generator: [],
          rejectionReason: [],
          reportPeriod: [],
          aff: [],
        },
      };
    },
    mounted() {
      // this.getAff();
      this.getOption();
    },
    methods: {
      // aff
      getAff() {
        getAffiliateList().then((res) => {
          this.options.aff = res.result;
        });
      },
      // 获取下拉参数
      getOption() {
        getSubruleOption().then((res) => {
          const result = res.result;
          this.options.changeInterval = result.changeInterval;
          this.options.checkInterval = result.checkInterval;
          this.options.att = result.filterAtt;
          this.options.compare = result.filterCompare;
          this.options.generator = result.generator;
          this.options.rejectionReason = result.rejectionReason;
          this.options.reportPeriod = result.reportPeriod;
        });
      },
      rejectionPostbackAdd() {
        this.postData.rejectionPostbackList.push({
          rejectionReason: '',
          rejectionCount: 0,
          managerAlert: 0,
          enginerAlert: 0,
          changeSub: 1,
          changeCount: 0,
          changeInterval: 0,
          excludeVba: 0,
        });
      },
      rejectionPostbackDelete(index) {
        this.postData.rejectionPostbackList.splice(index, 1);
      },
      reportAnaylsisAdd() {
        this.postData.reportAnalysisList.push({
          checkInterval: '',
          affiliateId: '',
          reportPeriod: 0,
          filterBeans: [],
          managerAlert: 0,
          enginerAlert: 0,
          changeSub: 1,
          changeCount: 0,
          changeInterval: '',
        });
      },
      reportAnaylsisDelete(index) {
        this.postData.reportAnalysisList.splice(index, 1);
      },
      whereAdd(index) {
        this.postData.reportAnalysisList[index].filterBeans.push({
          att: 'click',
          compare: '>',
          val: '0',
        });
      },
      whereDelete(index1, index2) {
        this.postData.reportAnalysisList[index1].filterBeans.splice(index2, 1);
      },
      UpNumber(e) {
        // 输入框中只允许输入数字
        // e.target.value = e.target.value.replace(/[^\d]/g, '');
        // 输入框中只允许输入小数点和数字，小数点后只能跟一位
        e.target.value = e.target.value.match(/^\d*(\.?\d{0,4})/g)[0] || null;
      },
      // 保存数据
      save(formName) {
        this.$refs[formName].validate((valid) => {
          if (!valid) return;
          this.loading.save = true;
          addSubrule(this.postData)
            .then((res) => {
              if (res.code == 200) {
                console.log(res);
                this.$message({
                  message: '添加成功',
                  type: 'success',
                });
                this.$router.push({
                  path: '/offer/subrule',
                });
              } else {
                this.loading.save = false;
                this.$message.error('添加失败！');
              }
            })
            .catch((error) => {
              console.log(error);
              this.loading.save = false;
              this.$message.error('添加失败！');
            });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
